import React, { useContext, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "../Button";
import { ModalField } from "./ModalField";

import ModalContext from "../../context/ModalContext";

import {
  ModalComponent,
  ModalForm as ModalFormComponent,
  ModalGrid,
  ModalHeader,
  ModalContent,
  ModalField as ModalFieldComponent,
  ErrorMessage,
  ModalButtonContainer,
} from "./FormModalStyles";
import { toast } from "react-toastify";

interface FormModalProps {
  onClose: () => void;
}

interface FormFields {
  produto: string;
  nome: string;
  email: string;
  phone: string;
  empresa: string;
  segmento: string;
  site: string;
  mensagem?: string;
}

const schema = yup.object().shape({
  produto: yup.string(),
  nome: yup.string().required("Campo obrigatório"),
  email: yup.string().required("Campo obrigatório").email("Email inválido"),
  phone: yup
    .string()
    .required("Campo obrigatório")
    .min(14, "Telefone inválido"),
  empresa: yup.string().required("Campo obrigatório"),
  segmento: yup.string(),
  site: yup.string().required("Campo obrigatório"),
  mensagem: yup.string().nullable(),
});

const FormModal: React.FC<FormModalProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormFields>({ resolver: yupResolver(schema) });
  const { activeProduct } = useContext(ModalContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    toast.success(({ closeToast, toastProps }) => (
      <div>
        <p style={{margin: 0, marginBottom: 5, fontSize: 14}}><strong>Obrigado pelo interesse!</strong></p>
        <p style={{margin: 0, fontSize: 13}}>Em breve entraremos em contato.</p>
      </div>
    ));

    handleClose();
  };

  const handleClose = () => {
    reset({
      email: "",
      empresa: "",
      mensagem: "",
      nome: "",
      phone: "",
      site: "",
    });

    onClose();
  };

  useEffect(() => {
    setValue("produto", activeProduct);
  }, [activeProduct]);

  return (
    <ModalComponent>
      <ModalHeader>
        {/* <h3>Agende uma apresentação</h3> */}
        <FaTimes onClick={handleClose} />
      </ModalHeader>

      <ModalContent>
      <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/c56JJz659dV8hx5xuRf1lC2pM8AXDudbIDBNqzfqSN1ZtoIrV8V6QIurn0m9xLMiHN" />
      </ModalContent>
    </ModalComponent>
  );
};

export { FormModal };
